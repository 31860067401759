import React from "react";
import Slider from "react-slick";

const testimonialContent = [
  {
    avatar: "irm",
    name: "Manager, Iron Mountain",
    email: "",
    hightlightText: "",
    date: "",
    description: `Ever since Fuzzer started its activity, they have been
    my number one recommendation for any business
    or entrepreneur that's looking for remarkable
    services. I recommend Fuzzer to anyone who wants
    an exceptional partnership with talented people
    that believe in what you do.`,
  },
  {
    avatar: "interbroker",
    name: "Owner, Inter Broker",
    email: "",
    hightlightText: "",
    date: "",
    description: `If you are looking for a team of super talented
    people who are up to speed with the latest
    trends in design - Fuzzer is your team! (...) They
    went above and beyond every time especially
    on delivery as most of the time we needed
    quick results. We highly recommend them!`,
  },
];

const TestimonialTwo = () => {
  const settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings} className="arrow-none">
        {testimonialContent.map((val, i) => (
          <div className="swiper-slide" key={i}>
            <div
              className="ptf-twitter-review ptf-twitter-review--style-1"
              key={i}
            >
              <div className="ptf-twitter-review__header">
                {/* <div className="ptf-twitter-review__avatar">
                  <img
                    src={`assets/img/root/${val.avatar}.png`}
                    alt="avatar"
                    loading="lazy"
                  />
                </div> */}
                <div className="ptf-twitter-review__meta">
                  <h6 className="ptf-twitter-review__author">{val.name}</h6>
                  {/* <div className="ptf-twitter-review__info">
                    <a href="mailto:ibthemes21@gmail.com">{val.email}</a> -{" "}
                    {val.date}
                  </div> */}
                </div>
                {/* <div className="ptf-twitter-review__icon">
                  <i className="socicon-twitter"></i>
                </div> */}
                <div className="ptf-twitter-review__avatar">
                  <img
                    src={`assets/img/root/${val.avatar}.png`}
                    alt="avatar"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="ptf-twitter-review__content">
                <p>
                  <a
                    href="https://twitter.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {val.hightlightText}
                  </a>{" "}
                  {val.description}
                </p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default TestimonialTwo;
