import React from "react";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";

const portfolioContent = [
  {
    cat: "Branding, Marketing Strategy, Photography/Videography",
    title: "Iron Mountain Creative Projects",
    date: "2022",
    routerLink:
      "https://www.behance.net/gallery/151466617/Iron-Mountain-Creative-Projects",
    imgPopup: "irm",
    dataId: "irm",
  },
  {
    cat: "Branding",
    title: "Traveler: Book Your Holiday Concept",
    date: "2022",
    routerLink:
      "https://www.behance.net/gallery/150434119/Traveler-Book-Your-Holiday-Concept",
    imgPopup: "traveler",
    dataId: "traveler",
  },
  {
    cat: "Employer Branding, Marketing Strategy",
    title: "PlayStation® Creative Recruitment Campaign",
    date: "2020",
    routerLink:
      "https://www.behance.net/gallery/161417309/PlayStation-Creative-Recruitment-Campaign",
    imgPopup: "ps",
    dataId: "ps",
  },
  {
    cat: "Branding, Web Design",
    title: "Therapist's Office Branding & Web Design",
    date: "2022",
    routerLink:
      "https://www.behance.net/gallery/150572107/Therapists-Office-Branding-Web-Design",
    imgPopup: "flo",
    dataId: "flo",
  },
  {
    cat: "Marketing Strategy",
    title: "Samsung 'Focus' Campaign",
    date: "2020",
    routerLink:
      "https://www.behance.net/gallery/161868055/Samsung-Focus-Campaign",
    imgPopup: "samsung",
    dataId: "samsung",
  },
  {
    cat: "Event Branding",
    title: "Event Branding: Mountaineer Festival",
    date: "2023",
    routerLink:
      "https://www.behance.net/gallery/174119801/2023-Event-Branding-Mountaineer-Festival",
    imgPopup: "mountfest",
    dataId: "mountfest",
  },
];

const PortfolioTwo = () => {
  return (
    <>
      <div className=" grid-item ">
        {/* <!--Portfolio Item--> */}
        {portfolioContent.map((val, i) => (
          <a target="_blank" href={`${val.routerLink}`} rel="noreferrer">
            <article
              className="ptf-work ptf-work--style-4"
              data-tip
              data-for={val.dataId}
              key={i}
            >
              {/* <Link className="ptf-work__link" to={`${val.routerLink}`}></Link> */}

              <ReactTooltip
                id={val.dataId}
                place="top"
                type="dark"
                effect="float"
              >
                <div className="poup-link">
                  <img
                    src={`assets/img/root/portfolio/${val.imgPopup}.webp`}
                    loading="lazy"
                    alt="popup"
                    width="auto"
                  />
                  {/* <picture>
                    <source
                      media="(max-width:650px)"
                      srcset="img_pink_flowers.jpg"
                    />
                    <source
                      media="(max-width:465px)"
                      srcset="img_white_flower.jpg"
                    />
                    <img
                      src={`assets/img/root/portfolio/${val.imgPopup}.webp`}
                      alt="popup"
                      style="width:auto;"
                    />
                  </picture> */}
                </div>
              </ReactTooltip>
              <div className="ptf-work__category">{val.cat}</div>
              <h4 className="ptf-work__title">{val.title}</h4>
              <div className="ptf-work__date">{val.date}</div>
            </article>
          </a>
        ))}
      </div>
      {/* End .grid-item */}
      <div></div>
      {/* End .grid-item */}
    </>
  );
};

export default PortfolioTwo;
