import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import CounterTwo from "../components/counter/CounterTwo";
import CopyRightTwo from "../components/footer/copyright/CopyRightTwo";
import FooterTwo from "../components/footer/FooterTwo";
import HeaderAgency from "../components/header/HeaderAgency";
import HeroAgency from "../components/hero/HeroAgency";
import ListOne from "../components/list/ListOne";
import ListTwo from "../components/list/ListTwo";
import PortfolioTwo from "../components/portfolio/PortfolioTwo";
import TestimonialTwo from "../components/testimonial/TestimonialTwo";
import Cookie from "../components/cookie-consent";

const HomeAgency = () => {
  return (
    <>
      <div className="ptf-site-wrapper animsition ptf-is--home-agency">
        <Helmet>
          <title>Fuzzer</title>
        </Helmet>
        {/* End Page SEO Content */}

        <div className="ptf-site-wrapper__inner">
          <HeaderAgency />
          {/* End Header Agency */}

          <div className="ptf-main">
            <div className="ptf-page ptf-page--home-agency">
              {/*=============================================
          Start Our Hero Section howcase 3
          ============================================== */}
              <div
                className="ptf-showcase-3 swiper-container"
                style={{ padding: "8px" }}
              >
                <div className="swiper-wrapper">
                  <HeroAgency />
                </div>
              </div>

              {/*=============================================
          Start Call to Counter section
          ============================================== */}
              <section style={{ padding: "8px" }}>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "3.75rem" }}
                ></div>
                <div className="container">
                  <div
                    className="row align-items-center"
                    style={{ "--bs-gutter-y": "2rem" }}
                  >
                    <div className="col-12 col-xl-6">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="0"
                      >
                        {/* <!--Phone Block--> */}
                        <div className="ptf-phone-block">
                          <div className="ptf-phone-block__icon">
                            <i className="lnil lnil-phone"></i>
                          </div>
                          <div className="ptf-phone-block__caption">
                            Call Us
                            <br />
                            Directly
                          </div>
                          <div className="ptf-phone-block__phone">
                            <a href="tel:+40752183833">(+40) 752 183 833</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End .col */}
                    <CounterTwo />
                  </div>
                </div>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "3.75rem" }}
                ></div>
              </section>

              <section style={{ padding: "8px" }}>
                <div className="container">
                  {/* <!--Divider--> */}
                  <div className="ptf-divider"></div>
                </div>
              </section>
              {/* End .ptf-devider */}

              <section style={{ padding: "8px" }}>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "8.125rem", "--ptf-md": "4.0625rem" }}
                ></div>
                <div className="container">
                  <div className="row">
                    <div className="col-xl-7">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="0"
                      >
                        <h2 className="h1 large-heading has-accent-1">
                          Work with us
                        </h2>
                      </div>
                    </div>
                    <div className="col-xl-5 d-none d-xl-block">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="100"
                      >
                        <div className="has-black-color fz-90 lh-1 text-end">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            style={{ height: "5em" }}
                            viewBox="0 0 17 17"
                          >
                            <path d="M16 .997V10h-1V2.703L4.683 13l-.707-.708L14.291 1.997H6.975v-1H16z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
                ></div>
              </section>
              {/* End  Advertising Big Title */}

              <section
                style={{ padding: "8px" }}
                className="ptf-custom--3993 jarallax"
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-5 offset-lg-1">
                      {/* <!--Spacer--> */}
                      <div
                        className="ptf-spacer"
                        style={{ "--ptf-xxl": "8.125rem", "--ptf-lg": "0" }}
                      ></div>
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block custom-padding"
                        data-aos="fade"
                        data-aos-delay="0"
                      >
                        <h6 className="fz-16 has-accent-1 text-uppercase">
                          About Us
                        </h6>
                        {/* <!--Spacer--> */}
                        <div
                          className="ptf-spacer"
                          style={{ "--ptf-xxl": "1.875rem" }}
                        ></div>
                        <h3
                          className="has-white-color"
                          style={{ maxWidth: "30rem" }}
                        >
                          We bring trusted solutions for your business
                        </h3>
                        {/* <!--Spacer--> */}
                        <div
                          className="ptf-spacer"
                          style={{ "--ptf-xxl": "5rem", "--ptf-md": "2.5rem" }}
                        ></div>

                        <p
                          className="fz-18 has-white-color"
                          style={{ maxWidth: "30rem" }}
                        >
                          Fuzzer is a Transylvania-based creative agency
                          specialized in offering inventive, results-driven
                          solutions that harness the highest immediate return of
                          investment for companies of all sizes.
                        </p>
                        <p
                          className="fz-18 has-white-color"
                          style={{ maxWidth: "30rem" }}
                        >
                          Our core services are anchored around creating
                          powerful brands, innovative campaigns, and impeccable
                          designs, crafting copywriting that drives clicks and
                          strategies that will place your brand on top of your
                          audiences' minds. Specialties include creative
                          production, go-to-market strategy, and market
                          research.
                        </p>
                      </div>
                      {/* <!--Spacer--> */}
                      <div
                        className="ptf-spacer"
                        style={{ "--ptf-xxl": "8.125rem", "--ptf-lg": "0" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </section>
              {/* End  Advertising Banner */}

              {/*=============================================
          Start Service section
          ============================================== */}

              <section style={{ padding: "8px" }}>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
                ></div>
                <div className="container">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h2 className="h1 large-heading has-accent-1">
                      Our Services
                    </h2>
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "4.375rem", "--ptf-md": "2.1875rem" }}
                  ></div>
                  <div
                    className="row"
                    style={{
                      "--bs-gutter-x": "10.625rem",
                      "--bs-gutter-y": " 5.3125rem",
                    }}
                  >
                    <div className="col-12 col-md-6">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="0"
                      >
                        <h2>Branding</h2>
                        {/* <!--Spacer--> */}
                        <div
                          className="ptf-spacer"
                          style={{ "--ptf-xxl": "1.5625rem" }}
                        ></div>
                        {/* <!--Divider--> */}
                        <div className="ptf-divider"></div>
                        {/* <!--Spacer--> */}
                        <div
                          className="ptf-spacer"
                          style={{ "--ptf-xxl": "3.125rem" }}
                        ></div>
                        {/* <!--Services List--> */}
                        <ListOne />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="100"
                      >
                        <h2>Brand Marketing </h2>
                        {/* <!--Spacer--> */}
                        <div
                          className="ptf-spacer"
                          style={{ "--ptf-xxl": "1.5625rem" }}
                        ></div>
                        {/* <!--Divider--> */}
                        <div className="ptf-divider"></div>
                        {/* <!--Spacer--> */}
                        <div
                          className="ptf-spacer"
                          style={{ "--ptf-xxl": "3.125rem" }}
                        ></div>
                        {/* <!--Services List--> */}
                        <ListTwo />
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
                ></div>
              </section>

              {/*=============================================
          Start Latest Works section
          ============================================== */}
              <section id="success-stories" style={{ padding: "8px" }}>
                <div className="container">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h2 className="h1 large-heading has-accent-1 d-inline-flex">
                      Latest Works
                    </h2>
                    <Link
                      className="ptf-link-with-arrow fz-18 text-uppercase d-none d-lg-inline-flex"
                      to="/works-masonry"
                      style={{ marginLeft: "5.625rem" }}
                    >
                      All Projects{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        style={{ height: "1em" }}
                        viewBox="0 0 17 17"
                      >
                        <path d="M16 .997V10h-1V2.703L4.683 13l-.707-.708L14.291 1.997H6.975v-1H16z" />
                      </svg>
                    </Link>
                  </div>
                  {/* End .ptf-animated-block */}

                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "6.25rem" }}
                  ></div>

                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <div className="ptf-isotope-grid">
                      <PortfolioTwo />
                    </div>
                    {/* End .ptf-isotope-grid */}
                  </div>
                </div>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
                ></div>
              </section>

              <section style={{ padding: "8px" }}>
                <div className="container">
                  {/* <!--Divider--> */}
                  <div className="ptf-divider"></div>
                </div>
              </section>

              {/*=============================================
          Start Testimonial section
          ============================================== */}
              <section style={{ padding: "8px" }}>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "8.75rem", "--ptf-md": "4.375rem" }}
                ></div>
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-8">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="0"
                      >
                        <h2 className="h1 large-heading has-accent-1">
                          Trusted By Clients
                        </h2>
                      </div>
                    </div>
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": " 4.375rem" }}
                  ></div>
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    {/* <!--Content Slider--> */}
                    <div className="ptf-content-slider swiper-container ">
                      <div className="swiper-wrapper testimonial-100-spacer">
                        <TestimonialTwo />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
                ></div>
              </section>

              {/*=============================================
          Start Footer section
          ============================================== */}
              <footer
                className="ptf-footer ptf-footer--style-3"
                style={{ padding: "8px" }}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-xl-10">
                      <div className="ptf-footer__top">
                        <FooterTwo />
                      </div>
                      {/* End .ptf-footer__top */}

                      <div className="ptf-footer__bottom">
                        <CopyRightTwo />
                      </div>
                      {/* End .ptf-footer__bottom */}
                    </div>
                  </div>
                </div>
              </footer>
            </div>
            {/* End .ptf-page */}
          </div>
          {/* End .ptf-main */}
        </div>
        {/* End .ptf-site-wrapper__inner */}
      </div>
      <Cookie />
    </>
  );
};

export default HomeAgency;
