import React from "react";
import { Link } from "react-router-dom";

const listContent = [
  "Audit & Strategy",
  "Naming & Tagline",
  "Brand Visual & Verbal Identity",
  "Branding Guidelines",
  "Employer Branding",
  "Market Research & Talent Mapping",
];

const ListOne = () => {
  return (
    <ul
      className="ptf-services-list ptf-services-list--style-5"
      style={{ "--ptf-font-family": "var(--ptf-primary-font)" }}
    >
      {listContent.map((val, i) => (
        <li key={i}>
          {/* <Link to="/coming-soon">{val}</Link> */}
          <a>{val}</a>
        </li>
      ))}
    </ul>
  );
};

export default ListOne;
