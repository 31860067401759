import React, { useEffect } from "react";
import AllRoutes from "./router/AllRoutes";
import ScrollToTop from "./components/ScrollToTop";
import ReactGA from "react-ga";
import AOS from "aos";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
// import { jarallax } from "jarallax";
import AnimatedCursor from "react-animated-cursor";

const TRACKING_ID = "G-TD198CL28R"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>Fuzzer Agency</title>
        <meta
          name="description"
          content="Fuzzer is a Transylvania-based creative agency
                          specialized in offering inventive, results-driven
                          solutions that harness the highest immediate return of
                          investment for companies of all sizes. Our core services are anchored around creating
                          powerful brands, innovative campaigns, and impeccable
                          designs, crafting copywriting that drives clicks and
                          strategies that will place your brand on top of your
                          audiences' minds. Specialties include creative
                          production, go-to-market strategy, and market
                          research."
        />
        <meta
          name="keywords"
          content="Fuzzer Cluj, Fuzzer Cluj-Napoca, Fuzzer Transylvania, Transylvania, Fuzzer Transilvania, Transilvania, Fuzzer Romania, Romania, Cluj-Napoca, Cluj, full-service, millenial, creative, agency, creative agency, results-driver, solutions, immediate return of investment, ROI, highest ROI, creative, creating powerful brands, powerful brands, innovative, campaigns, innovative campaigns, impeccable designs, craft, crafting copywriting, copywriting, strategies, audiences, creative production, market strategy, marketing, strategy, market research, research"
        />
        <meta
          name="google-site-verification"
          content="gjwNJD57u2PY6Zl9aJxCjBsPz7VEocCx2EmJcfMAO8M"
        />
        <script
          src="https://www.googletagmanager.com/gtag/js?id=G-TD198CL28R"
          strategy="afterInteractive"
        />
        <script id="google-analytics" strategy="afterInteractive">
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-TD198CL28R');
        `}
        </script>
        <script id="google-tag-manager" strategy="afterInteractive">
          {`
         (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','')
        `}
        </script>{" "}
      </Helmet>
      {/* End React Helmet for SEO */}

      <AnimatedCursor
        innerSize={8}
        outerSize={40}
        color="250, 69, 41"
        outerAlpha={0.3}
        innerScale={0.7}
        outerScale={1}
      />
      {/* End Animated Cursor */}

      <ScrollToTop />
      {/* End Scroll To Top */}

      <AllRoutes />
      {/* End All Routes */}
    </>
  );
};

export default App;
