import React from "react";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";

const OneImage = (img) => {
  return (
    <SimpleReactLightbox>
      <SRLWrapper>
        <div className="row" style={{ "--bs-gutter-y": "2rem" }}>
          <div className="col-6">
            {/* <!--Animated Block--> */}
            <div
              className="ptf-animated-block"
              data-aos="fade"
              data-aos-delay={0}
            >
              {/* <!--Simple Image--> */}
              <div className="ptf-simple-image">
                <a href={`assets/img/${img}.png`} rel="nofollow">
                  <img src={`assets/img/${img}.png`} alt="" loading="lazy" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </SRLWrapper>
    </SimpleReactLightbox>
  );
};

export default OneImage;
