/* eslint-disable */
import React from "react";
import CookieConsent from "react-cookie-consent";

const Cookie = () => {
  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="cookies_consent"
        expires={365000}
        hideOnAccept={true}
      >
        <h3 style={{ color: "#fff" }}>We respect your privacy</h3>
        We use cookies to enhance your experience, but we don't store sensitive
        personal info in them. For details, refer to our{" "}
        <a
          style={{ color: "#fa4529", textDecoration: "underline" }}
          href="/privacy-policy"
          aria-label="Privacy Policy"
        >
          Privacy Policy
        </a>
        .
      </CookieConsent>
    </div>
  );
};

export default Cookie;
