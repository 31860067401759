import React from "react";
import { Helmet } from "react-helmet";
import HeaderAgencyDark from "../../../../components/header/HeaderAgencyDark";
import CopyRightTwo from "../../../../components/footer/copyright/CopyRightTwo";
import FooterTwo from "../../../../components/footer/FooterTwo";
import ImageGridThree from "../../../../components/image-grid/ImageGridThree";

const IronMountain = () => {
  return (
    <div className="ptf-site-wrapper animsition ptf-is--work-showcase-1">
      <Helmet>
        <title>Iron Mountain Creative Projects</title>
      </Helmet>
      {/* End Page SEO Content */}
      <div className="ptf-site-wrapper__inner">
        <HeaderAgencyDark />
        {/* End  HeaderHomeDefault */}

        <div className="main">
          <article className="ptf-page ptf-page--single-work-1">
            <section>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
              ></div>
              <div className="container-xxl">
                <div className="row">
                  <div className="col-xl-6">
                    {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="0"
                    >
                      <h1
                        className="large-heading"
                        style={{ lineHeight: "5rem" }}
                      >
                        Iron Mountain Creative Projects
                      </h1>
                      {/* <!--Spacer--> */}
                      <div
                        className="ptf-spacer"
                        style={{ "--ptf-xxl": "4rem", "--ptf-md": "2.5rem" }}
                      ></div>
                      {/* <Social /> */}
                    </div>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{
                        "--ptf-lg": "4.375rem",
                        "--ptf-md": "2.1875rem",
                      }}
                    ></div>
                  </div>
                  {/* End .col */}
                  {/* <WorksCaseStudy /> */}
                </div>
              </div>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "2.25rem", "--ptf-md": "1rem" }}
              ></div>
            </section>

            <section>
              <div className="container-xxl">
                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  {/* <!--Simple Image--> */}
                  <div className="ptf-simple-image">
                    <a
                      href="assets/img/portfolio/single-work/content-image-1.png"
                      rel="nofollow"
                    >
                      <img
                        src="assets/img/portfolio/single-work/content-image-1.png"
                        alt="work"
                        loading="lazy"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </section>

            <section>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "3.125rem" }}
              ></div>

              <div className="container">
                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <p className="fz-30 has-black-color">
                    Iron Mountain Incorporated is a global business dedicated to
                    storing, protecting and managing information and assets.
                    Iron Mountain is not just on the 730th place in Top Fortune
                    1000, but it also the most trusted partner of global
                    companies, serving 95% of the companies in Top Fortune 1000.
                  </p>
                  <p className="fz-30 has-black-color">
                    Fuzzer, our Creative Agency, started working with Iron
                    Mountain Romania in February 2022 and as our work met
                    massive success we extended the partnership on a global
                    level. By the end of 2022 we delivered 113 projects that
                    include, but are not limited to: design, strategy, B2B
                    sales, internal communications, external communications,
                    social media management & strategy, research, advertising,
                    photo & video shooting, editing and event marketing.
                  </p>
                </div>

                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "4.375rem", "--ptf-md": "2.1875rem" }}
                ></div>

                {/* <!--Divider--> */}
                <div
                  className="ptf-divider"
                  style={{
                    "--ptf-height": "1px",
                    "--ptf-color": "var(--ptf-color-14)",
                  }}
                ></div>

                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
                ></div>

                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <h2>Photo-Video Shooting</h2>
                </div>

                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "1.5625rem" }}
                ></div>

                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <p className="fz-18">
                    Our agency's promise is that any brand that works with us
                    will stand out. Our work stands out in design, strategy,
                    suggestions, creativity and so much more. What most agencies
                    don't tell you is that standing out starts at being
                    authentic. To be authentic is harder than it seems because
                    it involves showing the real personality of your brand, your
                    people and your vision.
                  </p>
                  <p className="fz-18">
                    We wanted to find the authenticity in Iron Mountain and then
                    leverage it in every aspect of their internal and external
                    presence. To do so, we organised a day photo and video
                    shooting. After 8 good hours of filming and taking pictures,
                    we had 467 pictures and 6 hours and 43 minutes of footage
                    that we could use to make Iron Mountain stand out in all
                    projects. Here are just a few of the authentic pictures we
                    captured during the session.
                  </p>
                </div>

                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                ></div>

                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  {/* <!--Gallery--> */}
                  <ImageGridThree />
                </div>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                ></div>

                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <h2>Designing brand essentials</h2>
                </div>

                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "1.5625rem" }}
                ></div>

                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <p className="fz-18">
                    We created over 1250 business cards that embody the true
                    spirit of the Iron Mountain brand. Following the branding
                    guidelines, we designed a minimalist and visible business
                    card that will help all mountaineers network.
                  </p>
                  <p className="fz-18">
                    When it comes to global brands, assuring that all employees
                    follow the culture and processes can be hard. To make it
                    easier, we crafted a "How to guide for Hiring Managers" that
                    helps all Hiring Managers follow the procedures while
                    feeling like they read a magazine instead of a boring
                    regulation document. For the brochure we used real pictures
                    of Iron Mountain employees, pictures previously taken in a
                    photo & video shooting which we provided.
                  </p>
                </div>

                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                ></div>

                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  {/* <!--Gallery--> */}
                  <ImageGridThree />
                </div>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                ></div>

                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <h2>Research in EMEA Region</h2>
                </div>

                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "1.5625rem" }}
                ></div>

                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <p className="fz-18">
                    Making informed decisions is the single most important thing
                    to do in your business. We delivered 3 studies for Iron
                    Mountain EMEA, more specifically on 3 cities: Frankfurt,
                    Berlin and Slough. Each study was a 20+ pages exhaustive
                    research on the following:
                  </p>
                  <ol style={{ marginBottom: "0.5rem" }}>
                    <li>Overview & Market Analysis</li>
                    <li>Target Audience Data</li>
                    <li>Education of Audience & Market Data</li>
                    <li>Expats & Immigrants data (if relevant)</li>
                    <li>
                      Iron Mountain Competitors and how to stand out from them.
                    </li>
                    <li>
                      Our recommendation - top locations for expanding the
                      business, conclusions, best strategy to advertise and so
                      on.
                    </li>
                  </ol>
                  <p className="fz-18">
                    At the end of the studies we ran multiple successful
                    advertising campaigns to meet the objectives and KPIs
                    agreed.
                  </p>
                </div>

                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                ></div>

                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  {/* <!--Gallery--> */}
                  <ImageGridThree />
                </div>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                ></div>

                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <h2>Social media strategy, content & results</h2>
                </div>

                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "1.5625rem" }}
                ></div>

                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <p className="fz-18">
                    Best marketing doesn't sell products or services. It sells
                    emotions. As we mentioned at the beginning of the board,
                    authenticity is the core element of all our communications.
                    Using authenticity and a strategic approach, we're
                    displaying Iron Mountain as it is: a reliable, performant
                    and customer-oriented community of exceptional people.
                  </p>
                  <p className="fz-18">
                    After running a SWOT analysis, we established a strategy
                    using 3 types of content: educational, entertainment and
                    promotional content. Educational content talks about all
                    security & storage topics, providing valuable information on
                    protecting data. Entertainment posts are meant to create
                    engagement while we connect with the audience. The
                    promotional content includes testimonials, services, results
                    and many more.
                  </p>{" "}
                  <p className="fz-18">
                    To make sure we get the best organic results we also use
                    video content, on top of link sharing, text and image posts.
                    Please check out below some of the photo & video content we
                    created in 2022.
                  </p>
                </div>

                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                ></div>

                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  {/* <!--Gallery--> */}
                  <ImageGridThree />
                </div>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                ></div>
              </div>
            </section>
          </article>
          {/* End .ptf-page */}
        </div>
      </div>
      {/* End .main */}

      {/* <!--Footer--> */}
      <footer
        className="ptf-footer ptf-footer--style-3"
        style={{ padding: "8px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-10">
              <div className="ptf-footer__top">
                <FooterTwo />
              </div>
              {/* End .ptf-footer__top */}

              <div className="ptf-footer__bottom">
                <CopyRightTwo />
              </div>
              {/* End .ptf-footer__bottom */}
            </div>
          </div>
        </div>
      </footer>
    </div>
    // End .ptf-is--blog-grid
  );
};

export default IronMountain;
