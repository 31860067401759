import React from "react";
import { Link } from "react-router-dom";

const HeaderComingSoon = () => {
  return (
    <header className="ptf-header ptf-header--empty">
      <div className="ptf-navbar ptf-navbar--main ptf-navbar--transparent">
        <div className="container-xxl">
          <div className="ptf-navbar-inner">
            {/* <!--Logo--> */}
            <Link to="/" className="ptf-navbar-logo">
              <img
                className="black"
                src="assets/img/root/logo-black.webp"
                alt="logo"
                loading="lazy"
                width="156px"
              />
              <img
                className="white"
                src="assets/img/root/logo-white.webp"
                width="156px"
                alt="logo"
                loading="lazy"
              />
            </Link>
            {/* <!--Socials--> */}
            <div className="ptf-navbar-socials">
              {/* <!--Social Icon--> */}
              <a
                className="ptf-social-icon ptf-social-icon--style-3 facebook"
                href="https://www.facebook.com/fuzzer.agency"
                target="_blank"
                rel="noreferrer"
              >
                <i className="socicon-facebook"></i>
              </a>
              {/* <!--Social Icon--> */}
              <a
                className="ptf-social-icon ptf-social-icon--style-3 instagram"
                href="#https://www.instagram.com/fuzzer.agency/"
                target="_blank"
              >
                <i className="socicon-instagram"></i>
              </a>
              {/* <!--Social Icon--> */}
              <a
                className="ptf-social-icon ptf-social-icon--style-3 disqus"
                href="#https://www.designrush.com/agency/profile/fuzzer"
                target="_blank"
              >
                <i className="socicon-disqus"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderComingSoon;
