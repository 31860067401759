import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import emailjs from "@emailjs/browser";
import * as Yup from "yup";

const Newsletter = () => {
  // for validation
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Entered value does not match email format"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  async function onSubmit(data, e) {
    // display form data on success
    emailjs
      .send(
        "service_y1om21n",
        "template_r8zn4mf",
        {
          email: data.email,
        },
        "97TOj1z6PHRg7SSDC"
      )
      .then(
        (result) => {
          return;
        },
        (error) => {
          alert(error.text);
        }
      );

    // setTimeout(() => {
    //   messageRef.current.innerText = "";
    // }, 2000);

    e.target.reset();
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="ptf-subscribe-form ptf-subscribe-form--default"
      style={{ maxWidth: "36.25rem" }}
    >
      <div className="ptf-form-group">
        <i className="lnil lnil-envelope-alt"></i>
        <input
          type="email"
          placeholder="Email Address"
          {...register("email")}
          className={` ${errors.email ? "is-invalid" : ""}`}
        />
        <button>Subscribe</button>
      </div>
    </form>
    // {/* End subscribe form */}
  );
};

export default Newsletter;
