import React from "react";
import { Link } from "react-router-dom";

const listContent = [
  "Creative Campaigns & Communications",
  "Design, Copywriting & Collateral",
  "Commercial Photography & Video",
  "Content Creation",
  "Recruitment Marketing",
  "Marketing Strategy & Planning",
];

const ListTwo = () => {
  return (
    <ul
      className="ptf-services-list ptf-services-list--style-5"
      style={{ "--ptf-font-family": "var(--ptf-primary-font)" }}
    >
      {listContent.map((val, i) => (
        <li key={i}>
          {/* <Link to="/service-details">{val}</Link> */}
          <a>{val}</a>
        </li>
      ))}
    </ul>
  );
};

export default ListTwo;
