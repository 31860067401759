import React from "react";
import { Helmet } from "react-helmet";
import HeaderAgencyDark from "../../../../components/header/HeaderAgencyDark";
import CopyRightTwo from "../../../../components/footer/copyright/CopyRightTwo";
import FooterTwo from "../../../../components/footer/FooterTwo";
import ImageGridThree from "../../../../components/image-grid/ImageGridThree";
import ImageGridTwo from "../../../../components/image-grid/ImageGridThree";
import ImageGridFour from "../../../../components/image-grid/ImageGridFour";
import OneImage from "../../../../components/image-grid/OneImage";

const MountaineerFestival = () => {
  return (
    <div className="ptf-site-wrapper animsition ptf-is--work-showcase-1">
      <Helmet>
        <title>2023 Event Branding: Mountaineer Festival</title>
      </Helmet>
      {/* End Page SEO Content */}
      <div className="ptf-site-wrapper__inner">
        <HeaderAgencyDark />
        {/* End  HeaderHomeDefault */}

        <div className="main">
          <article className="ptf-page ptf-page--single-work-1">
            <section>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
              ></div>
              <div className="container-xxl">
                <div className="row">
                  <div className="col-xl-6">
                    {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="0"
                    >
                      <h1
                        className="large-heading"
                        style={{ lineHeight: "5rem" }}
                      >
                        2023 Event Branding: Mountaineer Festival
                      </h1>
                      {/* <!--Spacer--> */}
                      <div
                        className="ptf-spacer"
                        style={{ "--ptf-xxl": "4rem", "--ptf-md": "2.5rem" }}
                      ></div>
                      {/* <Social /> */}
                    </div>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{
                        "--ptf-lg": "4.375rem",
                        "--ptf-md": "2.1875rem",
                      }}
                    ></div>
                  </div>
                  {/* End .col */}
                  {/* <WorksCaseStudy /> */}
                </div>
              </div>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "2.25rem", "--ptf-md": "1rem" }}
              ></div>
            </section>

            <section>
              <div className="container-xxl">
                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  {/* <!--Simple Image--> */}
                  <div className="ptf-simple-image">
                    <a
                      href="assets/img/portfolio/single-work/content-image-1.png"
                      rel="nofollow"
                    >
                      <img
                        src="assets/img/portfolio/single-work/content-image-1.png"
                        alt="work"
                        loading="lazy"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </section>

            <section>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "3.125rem" }}
              ></div>

              <div className="container">
                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <p className="fz-30 has-black-color">
                    After last year’s succesful Mountaineer Festival edition,
                    this year we were back with the second edition. Last year we
                    build Mountaineer Festival from scratch (naming, logo,
                    colours, positioning), and this year we had the chance to
                    work again on this amazing project.
                  </p>
                  <p className="fz-30 has-black-color">
                    If last year’s main theme was sports, this year we’re
                    looking at mountaineer activities include a campfire,
                    playing the guitar, nature activities, garden games and many
                    more in an exclusive location.
                  </p>
                </div>

                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "4.375rem", "--ptf-md": "2.1875rem" }}
                ></div>

                {/* <!--Divider--> */}
                <div
                  className="ptf-divider"
                  style={{
                    "--ptf-height": "1px",
                    "--ptf-color": "var(--ptf-color-14)",
                  }}
                ></div>

                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
                ></div>

                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <h2>Festival creatives</h2>
                </div>

                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "1.5625rem" }}
                ></div>

                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <p className="fz-18">
                    Mountaineer Festival took place at Grádina cu Zestre, an
                    exclusive, private, and unique location that is just 44 2km
                    away from Cly-Napoca The location is set in a rural scenery,
                    surrounded by greenery, a concept that we also integrated in
                    our designs. Anyone who visits wil fest connected to nature
                    and charmed by the lights and decor of this special place.
                  </p>{" "}
                  <p className="fz-18">
                    To enhance the festival experience, we created fabric
                    wristbands, t-shirts for efficient organisation, a website
                    and more. Below youll see some of the creatives we crafted
                    to make Mountaineer Festival stand out as an exceptional
                    experience. When entering Mountaineer Festival, all
                    mountaineers would be met by the event planners that will
                    wear the festival themed t-shirt with a design that is
                    completed with a QR to efficiently sign the GDPR agreements.
                  </p>
                </div>

                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "1.5625rem" }}
                ></div>

                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <p className="fz-18">
                    When entering Mountaineer Festival, all mountaineers would
                    be met by the event planners that will wear the festival
                    themed t-shirt with a design that is completed with a QR to
                    efficiently sign the GDPR agreements.
                  </p>
                  {/* <!--Gallery--> */}
                  <OneImage img="about-us-image-2" />
                </div>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "1.5625rem" }}
                ></div>

                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <p className="fz-18">
                    After completing the GDPR form, mountaineers would receive a
                    festival wristband, more details about the schedule and a
                    map of the location.
                  </p>{" "}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "1.5625rem" }}
                  ></div>
                  {/* <!--Gallery--> */}
                  <OneImage img="about-us-image-2" />{" "}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "1.5625rem" }}
                  ></div>
                  <p className="fz-18">
                    Using the most qualitative textile fabric and a stunning
                    nature-thered graphic, we created a wristband that showcases
                    nature, lights and the location of the festival Moreover,
                    the fabric of the wristband is soft and easy on the wrist.
                  </p>
                </div>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                ></div>

                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <h2>Internal communications visuals</h2>
                </div>

                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "1.5625rem" }}
                ></div>
                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  {/* <!--Gallery--> */}
                  <ImageGridFour img="about-us-image-2" />
                </div>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                ></div>

                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <p className="fz-18">
                    We crafted a 25 pages communication plan that included all
                    event details and over ten communcations, each of them
                    displaying the location of the event and the activities we
                    were planning for the festival. Each intemal communication
                    included Iron Mountain branding, yet Mountaineer Festival
                    brought a different style and vibe than the usual Iron
                    Mountain communications. Mountaineer Festival had specific
                    header & footer that could easily stand out in an employee's
                    Inbox
                  </p>
                </div>

                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "1.5625rem" }}
                ></div>

                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  {/* <!--Gallery--> */}
                  <OneImage img="about-us-image-2" />
                </div>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                ></div>

                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <h2>Festival website</h2>
                </div>

                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "1.5625rem" }}
                ></div>

                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "1.5625rem" }}
                ></div>

                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  {/* <!--Gallery--> */}
                  <ImageGridFour />
                </div>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                ></div>
              </div>
            </section>
          </article>
          {/* End .ptf-page */}
        </div>
      </div>
      {/* End .main */}

      {/* <!--Footer--> */}
      <footer
        className="ptf-footer ptf-footer--style-3"
        style={{ padding: "8px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-10">
              <div className="ptf-footer__top">
                <FooterTwo />
              </div>
              {/* End .ptf-footer__top */}

              <div className="ptf-footer__bottom">
                <CopyRightTwo />
              </div>
              {/* End .ptf-footer__bottom */}
            </div>
          </div>
        </div>
      </footer>
    </div>
    // End .ptf-is--blog-grid
  );
};

export default MountaineerFestival;
