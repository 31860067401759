import React, { useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import ModalVideo from "react-modal-video";

const HeroAgency = () => {
  const [isOpen, setOpen] = useState(false);

  const settings = {
    dots: true,
    arrow: true,
    infinite: true,
    fade: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    // adaptiveHeight: true,
  };
  return (
    <>
      {/* <Slider {...settings}> */}
      <div className="ptf-showcase-image image-3">
        <div className="ptf-showcase-slide">
          <div className="container">
            <h1 className="fz-100 fz-90--lg lh-1 has-white-color">
              Making your <p className="has-accent-1">brand stand out</p>
            </h1>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "3.125rem" }}
            ></div>
            <p
              className="fz-24 has-white-color"
              style={{ maxWidth: "34.375rem" }}
            >
              From start-ups to Fortune 1000, we help companies have an impact,
              stay relevant and become remarkable.
            </p>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "3.625rem" }}
            ></div>
            <div className="d-flex">
              {/* <!--Button--> */}
              <a
                className="ptf-btn ptf-btn--primary"
                href="/contact"
                target="_self"
              >
                Contact Us
              </a>
              {/* <!--Video Button--> */}

              <div
                className="ptf-video-button"
                onClick={() => {
                  const element = document.getElementById("success-stories");
                  element.scrollIntoView();
                }}
                style={{
                  "--ptf-title-color": "var(--ptf-color-white)",
                  marginLeft: "2rem",
                }}
              >
                {/* <a href="#" rel="nofollow">
                  <i className="lnil lnil-play"></i>
                </a> */}
                <div className="ptf-video-button__title">
                  Success
                  <br />
                  stories
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End .ptf-showcase-image */}
      {/* </Slider> */}
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="1gyTUHP6ne8"
        onClose={() => setOpen(false)}
      />
      {/* End Youtube Modal video */}
    </>
  );
};

export default HeroAgency;
